import {DICTIONARY} from "@/constants";
import {WordDataRow} from "@/components/WordDataTable/WordDataTable";

export enum WordFinderMessageType {
  REGISTER_URL = "register_url",
  MATCH = "match",
  CONTAINS = "contains",
  STARTS_WITH = "starts_with",
  ENDS_WITH = "end_with",
  DICTIONARY_SIZE = "dictionary_size",
  RESPONSE = "response",
  SIZE = "size",
}

interface WordFinderMessageBase<T, D> {
  type: T;
  data: D;
}

interface WordFinderRegisterUrlData {
  url: string
}

export type WordFinderRegisterUrlMessage = WordFinderMessageBase<WordFinderMessageType.REGISTER_URL, WordFinderRegisterUrlData>;

interface WordFinderTrieQueryDataBase {
  pattern: string[];
  excludeCharacters: string[];
  dictionaries: DICTIONARY[];
}

interface WordFinderTrieQueryLengthDataBase extends WordFinderTrieQueryDataBase {
  exactLength: boolean;
}

export type WordFinderMatchMessage = WordFinderMessageBase<WordFinderMessageType.MATCH, WordFinderTrieQueryDataBase>;
export type WordFinderContainsMessage = WordFinderMessageBase<WordFinderMessageType.CONTAINS, WordFinderTrieQueryLengthDataBase>;
export type WordFinderStartsWithMessage = WordFinderMessageBase<WordFinderMessageType.STARTS_WITH, WordFinderTrieQueryLengthDataBase>;
export type WordFinderEndsWithMessage = WordFinderMessageBase<WordFinderMessageType.ENDS_WITH, WordFinderTrieQueryLengthDataBase>;

interface WordFinderDictionarySizeQuery {
  dictionary: DICTIONARY;
}

export type WordFinderDictionarySizeMessage = WordFinderMessageBase<WordFinderMessageType.DICTIONARY_SIZE, WordFinderDictionarySizeQuery>;
export type WordFinderTrieSizeMessage = WordFinderMessageBase<WordFinderMessageType.SIZE, undefined>;

export type WordFinderMessage =
  WordFinderRegisterUrlMessage |
  WordFinderMatchMessage |
  WordFinderContainsMessage |
  WordFinderStartsWithMessage |
  WordFinderEndsWithMessage |
  WordFinderDictionarySizeMessage |
  WordFinderTrieSizeMessage;

export interface WordFinderPromiseMessage {
  messageId: number;
  body: WordFinderMessage;
}

export interface WordFinderResponseBase<T> {
  messageId: number;
  body: T;
  error?: Error;
}

export interface WordFinderResponseBodyBase {
  isSuccess: boolean;
}

export interface WordFinderResponseBodyWords extends WordFinderResponseBodyBase {
  words: string[];
}

export interface WordFinderResponseBodyData extends WordFinderResponseBodyBase {
  data: WordDataRow[];
}

export type WordFinderRegisterUrlResponse = WordFinderResponseBase<WordFinderResponseBodyBase>;
export type WordFinderQueryResponse = WordFinderResponseBase<WordFinderResponseBodyData>;

export interface WordFinderResponseBodySize extends WordFinderResponseBodyBase {
  size: number;
}

export type WordFinderDictionarySizeResponse = WordFinderResponseBase<WordFinderResponseBodySize>;

export type WordFinderResponse =
  WordFinderRegisterUrlResponse |
  WordFinderQueryResponse |
  WordFinderDictionarySizeResponse;
